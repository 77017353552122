<template>
    <SearchIcon @click="openSearch" class="search-button" />
</template>

<script setup>
import SearchIcon from '@/assets/icons/search.svg';
import { useSearchModalStore } from '@/stores/useSearchModalStore'

const store = useSearchModalStore()

function openSearch() {
  store.open()
}
</script>

<style lang="scss" scoped>
.search-button {
    @apply cursor-pointer;
}
</style>
